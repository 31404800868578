import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

import logo from "../../logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <div className="footer-container">
          <div className="footer-logo-social">
            <img className="footer-logo" src={logo} alt="MBA Logo" />
          </div>

          <div className="footer-useful-links">
            <h2>Masters Basketball Academy</h2>
            <div className="footer-welcome">
              <ul>
                <li>
                  Welcome to The Masters Basketball Academy, where experienced
                  coaches provide individualized training for skill improvement,
                  strength, and endurance.
                </li>
              </ul>
            </div>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/profile.php?id=61561033602846"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              {/* <FontAwesomeIcon icon={faFacebook} /> */}
              {/* <FontAwesomeIcon icon={faTwitter} /> */}
              {/* <FontAwesomeIcon icon={faInstagram} /> */}
              <a
                href="https://www.instagram.com/mastersbasketball.academy2024/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              {/* <FontAwesomeIcon icon={faWhatsapp} /> */}
              <a
                href="https://wa.me/+919986508757"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
            </div>
          </div>
          <div className="footer-quick-links">
            <h2>Quick Links</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-mba">About MBA</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/events">Events</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="footer-contact-details">
            <h2>Contact Details</h2>
            <div className="contact-content">
              <h4>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Masters Basketball Academy,
Shri PejavaraShri VishweshaThirtha Samiji Park
			15thCross ,Income tax layout  Chandra layout , 	
			Bangalore  - 560072
              </h4>
            </div>
            <div className="contact-content">
              <h4>
                <FontAwesomeIcon icon={faPhone} /> +91 99865 08757
              </h4>
            </div>
            <div className="contact-content">
              <h4>
                <FontAwesomeIcon icon={faEnvelope} /> mastersbasketball.academy@gmail.com
              </h4>
            </div>
            {/* <div className="contact-content">
              <h4>
                <FontAwesomeIcon icon={faClock} /> 10:00 AM to 5:00 PM
              </h4>             
            </div> */}
          </div>
        </div>
        <div className="footer-text">
          <p>&copy; 2024 Masters Basketball Academy. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
