import React, { useEffect, useState } from 'react';
import { useLocation} from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import EventCard from './EventCard'; // Import the EventCard component
import PlayerCard from './PlayerCard'; // Import the PlayerCard component
import { Auth } from 'aws-amplify';
import './TeamDetail.css';

const TeamDetail = () => {
  const location = useLocation();
  // const { teamId } = location.state || {};
  const { teamId, teamName } = location.state || {};
  const [teamData, setTeamData] = useState(null); // Holds the RegisteredTeamDTO
  const [error, setError] = useState(null);
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTeamDetails = async () => {
      setLoading(true);
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
      //  console.log("team id - " + teamId);
        // Make the GET request to fetch team details by teamId
        const response = await axiosInstance.post("/team/details",{
            teamId: teamId 
          }, 
          {

            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
        }
      );

        setTeamData(response.data); // Store the response in state
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError('Error fetching team details.');
      }
    };

    if (teamId) {
      fetchTeamDetails();
    }
  }, [teamId]);

  if (error) {
    return <p>{error}</p>;
  }

  // async function getState(route)  {
  //   setLoading(true);
  //   try {
  //     const session = await Auth.currentSession();
  //     const token = session.getIdToken().getJwtToken();
  
  //     const response = await axiosInstance.get("/team/resume/playerform", {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     const teamDetails = response.data;
  //     setLoading(false);
  //     if (route === 'players') {
  //       navigate('/events/register/players', { state: { teamDetails } });
  //     } else if (route === 'payment') {
  //       navigate('/events/register/payment', { state: { teamDetails } });
  //     }
  //   } catch (error) {
  //     setError("Error fetching teams.");
  //     setLoading(false);
  //   }
  // }

  if (loading) {
    return <div className="loading-overlay">
    <div className="loading-text">Loading, please wait...</div>
  </div>;
  }


  return (
    <div className="team-detail-container">
      <h1 className='team-detail-headling'>Team Details</h1>
  
      {/* Display Events */}
      {teamData && teamData.registeredEventDTOs.length > 0 && (
        <div>
          <h1 >Registered Events</h1>
          <div className="grid-container">
            {teamData.registeredEventDTOs.map((event, index) => (
              <EventCard key={index} event={event} /> // Pass each event to EventCard
            ))}
          </div>
        </div>
      )}
  
      {/* Display Players */}
      {teamData && teamData.players.length > 0 && (
        <div>
          {/* {console.log(teamData.players[0].team.name)} */}
          <h1 > {teamData.players[0].team.name} Players</h1>
          {/* {teamData.players.length < 12 &&(
            <div className="resume-state">
            <button onClick={() => getState('players')}>Add More Players</button>
          </div>
          )} */}
          <div className="grid-container">
            {teamData.players.map((player, index) => (
              <PlayerCard key={index} player={player} /> // Pass each player to PlayerCard
            ))}
          </div>
        </div>
      )}
  
      {!teamData && <div className="loading-overlay">
        <div className="loading-text">Loading team details...
        </div></div>}
    </div>
  );
  
};

export default TeamDetail;
