import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosConfig";
import { toast } from "react-toastify";
import "./PlayerRegistrationForm.css";
import DatePicker from "react-datepicker";
import chunk from "lodash/chunk";

import "react-datepicker/dist/react-datepicker.css";
import { Auth } from "aws-amplify";

const PlayerRegistrationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const teamDetails = state?.teamDetails;
  const [loading, setLoading] = useState(false);

 

  const [currentPlayer, setCurrentPlayer] = useState({
    name: "",
    jerseyNo: "",
    dob: "",
    gender: "",
    street: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    mobileNo: "",
    photo: "",
    captain: false,
    emergencyContactName: "",
    relationWithPlayer: "",
    emergencyContactMobileNo: "",
  });

  const [players, setPlayers] = useState([]);
  const [isReviewing, setIsReviewing] = useState(false);
  const [photoPreviews, setPhotoPreviews] = useState("");
  const [captainChoosed, setCaptainChoosed] = useState(false);
  const totalPlayers = Number(players.length) + Number(teamDetails.playerCount);
  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleDateChange = (date) => {
    if (
        calculateAge(date) > teamDetails.ageCategory.maxAge ||
        calculateAge(date) < teamDetails.ageCategory.minAge
      ) {
        toast.warn(
          `Your age should be between ${teamDetails.ageCategory.minAge}  And ${teamDetails.ageCategory.maxAge} `
        );
        setCurrentPlayer({ ...currentPlayer, dob: "" });
        return;
      }
     

    setCurrentPlayer({
      ...currentPlayer,
      dob: date
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "gender" && teamDetails.gender !== "All") {
      if (teamDetails.gender && value !== teamDetails.gender) {
        toast.warn(`This team is only for ${teamDetails.gender}`);
        return;
      }
    }
    if (name === "dob") {
      if (
        calculateAge(value) > teamDetails.ageCategory.maxAge ||
        calculateAge(value) < teamDetails.ageCategory.minAge
      ) {
        toast.warn(
          `Your age should be between ${teamDetails.ageCategory.minAge}  And ${teamDetails.ageCategory.maxAge} `
        );
        setCurrentPlayer({ ...currentPlayer, dob: "" });
        return;
      }
     
    }

    setCurrentPlayer({ ...currentPlayer, [name]: value });
  };


  // Handle photo upload
  const handlePhotoUpload = (e) => {
    // const file = e.target.files[0];
    // if (file && file.size < 5000000) { // Limit file size to 5MB
    //    setCurrentPlayer({ ...currentPlayer, photo: file });
    // } else {
    //    toast.warn("File size must be less than 5MB");
    // }
    const { name } = e.target;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setCurrentPlayer({
        ...currentPlayer,
        [name]: reader.result.split(",")[1],
      }); // set Base64 string
      setPhotoPreviews(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const addPlayer = () => {
  //   console.log("previous players - " + teamDetails.playerCount);
  //   console.log("player list - " + Number(players.length));
    // const totalPlayers = Number(players.length) + Number(teamDetails.playerCount);
    // console.log("player count - " + totalPlayers);
    for (const [key, value] of Object.entries(currentPlayer)) {
      if (value === "") {
        toast.warn(`${key} is required`);
        return;
      }
    }
    if (currentPlayer.postalCode.length !== 6) {
      toast.warn("Postal code must be 6 digits");
      return;
    }
    if (currentPlayer.mobileNo.length !== 10) {
      toast.warn("Player Mobile number must be 10 digits");
      return;
    }
    if (currentPlayer.emergencyContactMobileNo.length !== 10) {
      toast.warn("Emergency Mobile number must be 10 digits");
      return;
    }
    if(currentPlayer.captain){
      setCaptainChoosed(true);
    }
    setPlayers([...players, { ...currentPlayer, teamId: teamDetails.id }]);
    resetForm(); // Reset form after player is added
    toast.success("Player added successfully");
  };

  const resetForm = () => {
    setPhotoPreviews("");
    setCurrentPlayer({
      name: "",
      jerseyNo: "",
      dob: "",
      gender: "",
      street: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      mobileNo: "",
      photo: "",
      captain: false,
      emergencyContactName: "",
      relationWithPlayer: "",
      emergencyContactMobileNo: "",
    });
  };

  // Remove a player from the list
  const removePlayer = (index) => {
    // const isCaptain = players[index].captain;
    // console.log("is captain- " + players[index].captain);
    if(players[index].captain){
      setCaptainChoosed(false);
    }
    const updatedPlayers = players.filter((_, i) => i !== index);
    setPlayers(updatedPlayers);
  };


  // const handleSubmitAllPlayers = async () => {
  //   setLoading(true);
  //   try {
      
  //     // Prepare the players' data as an array of playerDTOs
  //     const playerDTOs = players.map((player) => ({
  //       name: player.name,
  //       jerseyNo: player.jerseyNo,
  //       dob: player.dob,
  //       gender: player.gender,
  //       street: player.street,
  //       city: player.city,
  //       state: player.state,
  //       country: player.country,
  //       postalCode: player.postalCode,
  //       mobileNo: player.mobileNo,
  //       photo: player.photo, // Make sure this is the Base64 string
  //       captain: player.captain,
  //       emergencyContactName: player.emergencyContactName,
  //       relationWithPlayer: player.relationWithPlayer,
  //       emergencyContactMobileNo: player.emergencyContactMobileNo,
  //       teamId: teamDetails.id,
  //     }));

  //     const session = await Auth.currentSession();
  //     const token = session.getIdToken().getJwtToken();

  //     // Send the data as JSON
  //     const response = await axiosInstance.post(
  //       "/registration/players",
  //       playerDTOs,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setCaptainChoosed(false);
  //     toast.success("Player Added Successfully: ");
     
  //     if(teamDetails.paymentDone === true){
  //       navigate('/profile');
  //     }else{
  //       navigate("/events/register/payment", { state: { teamDetails } });
  //     }
      
  //   } catch (error) {
  //     console.error("Error registering players:", error);
  //     toast.error(
  //       "Error registering players: " + error.response?.data || error.message
  //     );
  //   }finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmitAllPlayers = async () => {
    setLoading(true);
    try {
      // Prepare the players' data as an array of playerDTOs
      const playerDTOs = players.map((player) => ({
        name: player.name,
        jerseyNo: player.jerseyNo,
        dob: player.dob,
        gender: player.gender,
        street: player.street,
        city: player.city,
        state: player.state,
        country: player.country,
        postalCode: player.postalCode,
        mobileNo: player.mobileNo,
        photo: player.photo, // Make sure this is the Base64 string
        captain: player.captain,
        emergencyContactName: player.emergencyContactName,
        relationWithPlayer: player.relationWithPlayer,
        emergencyContactMobileNo: player.emergencyContactMobileNo,
        teamId: teamDetails.id,
      }));
  
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
  
      // Function to send a single batch
      const sendBatch = async (batch) => {
        const response = await axiosInstance.post(
          "/registration/players",
          batch,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response;
      };
  
      // Chunk the data into batches of 4
      const batches = chunk(playerDTOs, 4); // Using lodash's chunk utility for simplicity
  
      // Process each batch sequentially
      for (let i = 0; i < batches.length; i++) {
        const batch = batches[i];
        try {
          await sendBatch(batch); // Send the current batch
          // toast.success(`Batch ${i + 1} submitted successfully!`);
          console.log(`Batch ${i + 1} submitted successfully!`);
        } catch (error) {
          console.error(`Error in batch ${i + 1}:`, error);
          toast.error(
            `Error registering batch ${i + 1}: ${
              error.response?.data || error.message
            }`
          );
          throw error; // Stop further batches if an error occurs
        }
      }
      toast.success(`Players submitted successfully!`);

      // Navigate after all batches are successfully submitted
      setCaptainChoosed(false);
      if (teamDetails.paymentDone === true) {
        navigate("/profile");
      } else {
        navigate("/events/register/payment", { state: { teamDetails } });
      }
    } catch (error) {
      console.error("Error registering players:", error);
      toast.error(
        "Error registering players: " + error.response?.data || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="home">
      <div className="top-heading">
        <h1>Player Registration for Team: {teamDetails?.name}</h1>
      </div>

      {!isReviewing ? (
        <div className="player-detail-form-container">
          <form>
            <h2>Player Details of Player No - {totalPlayers + 1}</h2>
            <label className="player-registration-form-label">
              Player Name: <span style={{ color: "red" }}>*</span>
            </label>
            <div className="player-detail-full-length-textbox">
              <input
                type="text"
                name="name"
                placeholder="Player Name"
                value={currentPlayer.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="player-details-display-flex">
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none" }}
                  className="player-registration-form-label"
                >
                  Jersey Number: <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  name="jerseyNo"
                  placeholder="Jersey Number"
                  value={currentPlayer.jerseyNo}
                  onChange={handleChange}
                  min="0"
                  required
                />
              </div>
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none" }}
                  className="player-registration-form-label"
                >
                  Date of Birth: <span style={{ color: "red" }}>*</span>
                </label>
                <DatePicker
                  selected={currentPlayer.dob ? new Date(currentPlayer.dob) : null} 
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy" 
                  showYearDropdown      
                  showMonthDropdown       
                  dropdownMode="select"   
                  placeholderText="Select Date of Birth"
                  required
                />
              </div>
            </div>
            <div className="player-details-display-flex">
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none" }}
                  className="player-registration-form-label"
                >
                  Gender: <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  name="gender"
                  value={currentPlayer.gender}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none", visibility: "hidden"  }}
                  className="player-registration-form-label"
                >
                 Team Captain
                </label>
                <label style={{ border: "none"}}>
                  Team Captain
                  <input
                    className="player-detail-check-box"
                    type="checkbox"
                    name="captain"
                    checked={currentPlayer.captain}
                    disabled={captainChoosed}
                    onChange={(e) =>
                      setCurrentPlayer({
                        ...currentPlayer,
                        captain: e.target.checked,
                      })
                    }
                  />
                </label>
              </div>
            </div>
            <h2>Address</h2>
            <div className="player-detail-full-length-textbox">
              <label
                style={{ border: "none" }}
                className="player-registration-form-label"
              >
                Street: <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                type="text"
                name="street"
                placeholder="Street"
                value={currentPlayer.street}
                onChange={handleChange}
                required
              />
            </div>
            <div className="player-details-display-flex">
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none" }}
                  className="player-registration-form-label"
                >
                  City: <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={currentPlayer.city}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none" }}
                  className="player-registration-form-label"
                >
                  State: <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="state"
                  placeholder="State"
                  value={currentPlayer.state}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="player-details-display-flex">
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none" }}
                  className="player-registration-form-label"
                >
                  Country: <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={currentPlayer.country}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none" }}
                  className="player-registration-form-label"
                >
                  Postal Code: <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  name="postalCode"
                  placeholder="Postal Code"
                  value={currentPlayer.postalCode}
                  onChange={handleChange}
                  min="0"
                  required
                />
              </div>
            </div>
            <h2>Contact Information</h2>
            <div className="player-details-display-flex">
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none" }}
                  className="player-registration-form-label"
                >
                  Mobile Number: <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  name="mobileNo"
                  placeholder="Mobile Number"
                  value={currentPlayer.mobileNo}
                  onChange={handleChange}
                  min="0"
                  required
                />
              </div>
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none" }}
                  className="player-registration-form-label"
                >
                  Photo: <span style={{ color: "red" }}>*</span>
                </label>
                <div className="player-details-photo-preview-box" >
                <input
                  type="file"
                  name="photo"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                />
                {photoPreviews ? (
                
                <img 
                  className="player-detail-photo-preview"
                  src={photoPreviews}
                  alt="Player Preview"
                />
              ) : (
                <p className="player-detail-photo-preview"></p>
              )}
            
            </div>
              </div>
             
              
            
            
            </div>

            <h2>Emergency Contact</h2>
            <div className="player-detail-full-length-textbox">
              <label
                style={{ border: "none" }}
                className="player-registration-form-label"
              >
                Emergency Contact Name: <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="emergencyContactName"
                placeholder="Emergency Contact Name"
                value={currentPlayer.emergencyContactName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="player-details-display-flex">
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none" }}
                  className="player-registration-form-label"
                >
                  Relation with Player: <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="relationWithPlayer"
                  placeholder="Relation with Player"
                  value={currentPlayer.relationWithPlayer}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="player-detail-half-length-textbox">
                <label
                  style={{ border: "none" }}
                  className="player-registration-form-label"
                >
                  Emergency Contact Mobile No:{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  name="emergencyContactMobileNo"
                  placeholder="Emergency Contact Mobile No"
                  value={currentPlayer.emergencyContactMobileNo}
                  onChange={handleChange}
                  min="0"
                  required
                />
              </div>
            </div>
          </form>
          <div className="player-registration-button-container">
            {totalPlayers <= 12 && (
              <button
                className="player-registration-add-player-btn"
                type="button"
                onClick={addPlayer}
              >
                Add Player
              </button>
            )}

            {totalPlayers >= 2 && (
              <button
                type="button"
                className="player-registration-save-and-review-btn"
                onClick={() => {
                  // Scroll to the top of the page
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth', // Optional: for smooth scrolling
                  });
                  
                  // Set reviewing state to true
                  setIsReviewing(true);
                }}
              >
                Review & Save
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="review-players">
          <h2>Review Players</h2>
          <div className="player-register-review-player-details">
            {players.map((player, index) => (
              <div className="player-card" key={index}>
                <div className="player-register-player-photo-container">
                  <img
                    className="player-register-player-photo"
                    src={`data:image/jpeg;base64,${player.photo}`}
                    alt={`${player.name}`}
                  />
                </div>

                <div>
                  <strong>Sl. No-:</strong> {index + 1}
                </div>
                <div>
                  <strong>Name:</strong> {player.name}{" "}
                  <strong>{player.captain ? "(C)" : ""}</strong>
                </div>
                <div>
                  <strong>Jersey No:</strong> {player.jerseyNo}
                </div>
                <div>
                  <strong>Date of Birth:</strong> {player.dob.toLocaleDateString()}
                </div>
                <div>
                  <strong>Gender:</strong> {player.gender}
                </div>
                <div>
                  <strong>Mobile No:</strong> {player.mobileNo}
                </div>
                <div>
                  <strong>Address:</strong>{" "}
                </div>
                <div  className="player-register-player-address">
                  {player.street}, {player.city}, {player.state},{" "}
                  {player.country}
                </div>
                {/* <div><strong>State:</strong> {player.state}</div>
            <div><strong>Country:</strong> {player.country}</div> */}
                <div>
                  <strong>Postal Code:</strong> {player.postalCode}
                </div>

                <div>
                  <strong>Emergency Contact</strong>
                </div>
                <div>
                  <strong> Name:</strong> {player.emergencyContactName}
                </div>
                <div>
                  <strong>Relation with Player:</strong>{" "}
                  {player.relationWithPlayer}
                </div>
                <div>
                  <strong>Contact No:</strong> {player.emergencyContactMobileNo}
                </div>

                <button
                  className="remove-button"
                  type="button"
                  onClick={() => removePlayer(index)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
          <div className="player-registrtion-add-submit-button">
            {totalPlayers <= 12 && (
              <button type="button" onClick={() => {
                // Scroll to the top of the page
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth', // Optional: for smooth scrolling
                });
                setIsReviewing(false)}}>
                Add More Players
              </button>
            )}
            {totalPlayers >= 2 && totalPlayers <= 12 && (
              <button
                className="player-registration-submit-btn"
                type="button"
                onClick={handleSubmitAllPlayers}
              >
                Submit All Players
              </button>
            )}
            {totalPlayers > 12 &&(
              <h2>You cannot add more than 12 players. Please remove {totalPlayers - 12} {" "}
    {totalPlayers - 12 === 1 ? "Player" : "Players"} to proceed</h2>
            )}
          </div>
        </div>
      )}
      {loading && (
              <div className="loading-overlay">
                <div className="loading-text">Loading, please wait...</div>
              </div>
            )}
    </div>
  );
};

export default PlayerRegistrationForm;
