import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './navbar.css';
import logo from '../../logo1.png';
import { Auth } from 'aws-amplify';

const Navbar = ({ isAuthenticated, updateAuthStatus }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownAdmin, setShowDropdownAdmin] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null); 
  const [username, setUsername] = useState('');
  const [showUserDropdown, setShowUserDropdown] = useState(false); 


  // Check for token in local storage when the component mounts
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        // If a session is valid, Auth.currentSession() will not throw an error
        // await Auth.currentSession();
        const session = await Auth.currentSession();
        updateAuthStatus(true);
        const role = session.idToken.payload['custom:role'];
        const name = session.idToken.payload['cognito:username'];

        // console.log("user name is - " + name);
        // console.log("role is - " + role);
        setUserRole(role);
        setUsername(name);
      } catch {
        updateAuthStatus(false);
        setUserRole(null);
        setUsername('');
      }
    };

    checkAuthStatus();
  }, [updateAuthStatus]);

  const handleLogout = async () => {
    try {
      // console.log('Logout');
      await Auth.signOut();
      updateAuthStatus(false);
      navigate('/');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <nav className="nav-bar">
      <div className="navbar-logo">
        <Link to="/">
          <img src={logo} alt="MBA Logo" />
        </Link>
      </div>
      <button className="mobile-menu-toggle" onClick={() => setShowMobileMenu(!showMobileMenu)}>
        &#9776;
      </button>
      <ul className={`nav-links ${showMobileMenu ? 'show' : ''}`}>
        <li><Link to="/">Home</Link></li>
        <li 
          className="dropdown" 
          onMouseEnter={() => setShowDropdown(true)} 
          onMouseLeave={() => setShowDropdown(false)}
        >
          <Link to="/about-mba">About MBA</Link>
          {showDropdown && (
            <ul className="dropdown-menu">
              <li><Link to="/members">Members</Link></li>
              <li><Link to="/about-mba/organisation">Organisation</Link></li>
            </ul>
          )}
        </li>
        <li><Link to="/academy">Academy</Link></li>
        <li><Link to="/gallery">Gallery</Link></li>
        <li><Link to="/events">Events</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        
        
        {!isAuthenticated && (
          <>
            <li><Link to="/login">Login / Sign up</Link></li>
          </>
        )}
        {isAuthenticated && userRole === 'Admin' && (
          <li><Link to="/display">Display</Link></li>
        )}
        {isAuthenticated && userRole === 'Admin' && (
          <li><Link to="/controller">Controller</Link></li>
        )}
        {isAuthenticated && userRole === 'Admin' && (
          <li 
            className="dropdown" 
            onMouseEnter={() => setShowDropdownAdmin(true)} 
            onMouseLeave={() => setShowDropdownAdmin(false)}
          >
            <Link to="/admin">Admin</Link>
            {showDropdownAdmin && (
              <ul className="dropdown-menu">
                <li><Link to="/admin/validate">Validate Payments</Link></li>
                <li><Link to="/admin/success/payment">Success Payments</Link></li>
                <li><Link to="/member">Add Members</Link></li>
                <li><Link to="/createevent">Events</Link></li>
                <li><Link to="/address">Address</Link></li>
                <li><Link to="/category">Age Category</Link></li>
              </ul>
            )}
          </li>
        )}
        {/* {isAuthenticated && (
          <li><Link to="/" onClick={handleLogout}>Logout</Link></li>
        )} */}
        {isAuthenticated && (
          <li className="username-display dropdown"
          onMouseEnter={() => setShowUserDropdown(true)} 
          onMouseLeave={() => setShowUserDropdown(false)}
          >
            <span>Welcome, {username}</span>
            {showUserDropdown && (
              <ul className="dropdown-menu">
                <li><Link to="/profile">Dashboard</Link></li>
                <li stclassName="nav-logoutbutton"><button onClick={handleLogout}>Logout</button>  </li>
              </ul>
            )}
          </li>
        )}
        {/* {isAuthenticated && (
          <li
            className="username-display"
            onClick={() => setShowUserDropdown(!showUserDropdown)} // Toggle the dropdown on click
          >
            <span>Welcome, {username}</span>
            {showUserDropdown && (
              <ul className="user-dropdown">
                <li><Link to="/profile">Profile</Link></li>
                <li><button onClick={handleLogout}>Logout</button></li>
              </ul>
            )}
          </li>
        )} */}
      </ul>
    </nav>
  );
};

export default Navbar;
