// UserDashboard.js
import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import axios from "axios";
import TeamCard from "./TeamCard";
import "./UserDashboard.css"; // Create a CSS file for styles (optional)
import { Auth } from "aws-amplify";
import axiosInstance from "../../axiosConfig";
import StatusTimeline from "../statusTimeline/StatusTimeline";
import CustomAlert from "../CustomAlert";

const UserDashboard = () => {
  const [teams, setTeams] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedTeamId, setSelectedTeamId] = useState(null); 
  const navigate = useNavigate();
  const [status, setStatus] = useState(""); 
  const [onConfirmAction, setOnConfirmAction] = useState(null);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();

        const response = await axiosInstance.get("/team/all/teams", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
        });
        setTeams(response.data);

        const statusResponse = await axiosInstance.get("/team/status", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setStatus(statusResponse.data);
        if(statusResponse.data === 'Payment failed.'){
          setAlertMessage('Your payment appears to have failed. Please retry the payment.If you believe you entered a valid transaction ID or need further assistance, please contact us.');
          setShowAlert(true);
        }

        setLoading(false);
      } catch (error) {
        setError("Error fetching teams.");
        setLoading(false);
      }
    };

    fetchTeams();
  }, []);

  if (loading) {
    return <div className="loading-overlay">
    <div className="loading-text">Loading, please wait...</div>
  </div>;
  }

  
  // async function getState(route)  {
  //   try {
  //     const session = await Auth.currentSession();
  //     const token = session.getIdToken().getJwtToken();
  
  //     const response = await axiosInstance.get("/team/resume/playerform", {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     const teamDetails = response.data;
  //     if (route === 'players') {
  //       navigate('/events/register/players', { state: { teamDetails } });
  //     } else if (route === 'payment') {
  //       navigate('/events/register/payment', { state: { teamDetails } });
  //     }
  //   } catch (error) {
  //     setError("Error fetching teams.");
  //     setLoading(false);
  //   }
  // }
  

  if (error) {
    return <p>{error}</p>;
  }

  // const handleViewDetails = (teamId) => {
  //   setSelectedTeamId(teamId);
  //   // console.log("Selected Team ID:", teamId);
  //   navigate('/team/details', { state: { teamId } });
  // };
  const handleViewDetails = (teamId, teamName) => {
    setSelectedTeamId(teamId);
    // Optionally log the details
    console.log("Selected Team ID:", teamId, "Selected Team Name:", teamName);
  
    // Navigate with both teamId and teamName
    navigate('/team/details', { state: { teamId, teamName } });
  };
  

  return (
    <div className="home">
      <div className="team-status">
          <h2>Team Status: {status}</h2>
        </div>
        <div className="team-status">
          <h1 style={{textAlign:'center'}}>Team Status</h1>
          <StatusTimeline currentStatus={status} /> {/* Pass status to the timeline */}
        </div>
        
      <div style={{ height: "10vh" }} className="top-heading">
        <h1>Team Details</h1>
      </div>
    <div className="user-dashboard">
      {/* <h2>Your Teams</h2> */}
      <div className="team-list">
        {teams.length > 0 ? (
          teams.map((team) => <TeamCard key={team.id} team={team} 
          
          // onViewDetails={handleViewDetails}
          onViewDetails={(id, name) => handleViewDetails(id, name)}
          />)
        ) : (
          <p>No teams found.</p>
        )}
      </div>
      {selectedTeamId && (
        <div className="team-details">
          <h3>Selected Team ID: {selectedTeamId}</h3>
          {/* You can add more logic to display full details of the selected team */}
        </div>
      )}
    </div>
    <CustomAlert 
        show={showAlert} 
        message={alertMessage} 
        onClose={() => setShowAlert(false)} 
        onConfirm={onConfirmAction}  // Pass the confirm action (if any)
      />
    </div>
  );
};

export default UserDashboard;
