import React, { useEffect, useState } from 'react';
import './PaymentValidation.css'; // Import your CSS file for styling
import axiosInstance from '../../axiosConfig';
import { toast } from "react-toastify";
import { Auth } from 'aws-amplify';

const SuccessPayment = () => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(0); // Current page number
    const [totalPages, setTotalPages] = useState(0); // Total pages available
    const pageSize = 5; // Number of records per page

    const fetchPayments = async (page) => {
        try {
            setLoading(true);
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            const response = await axiosInstance.get(`/admin/success/validation?page=${page}&size=${pageSize}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            setPayments(response.data.content); // `content` holds the current page's records
            setTotalPages(response.data.totalPages); // `totalPages` from the API response
            setCurrentPage(response.data.number); // `number` indicates the current page
        } catch (err) {
            console.error('Error fetching payments:', err);
            setError('Error fetching payments');
            toast.error('Error fetching payments');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPayments(currentPage); // Fetch the initial page
    }, [currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (loading) {
        return (
            <div className="loading-overlay">
                <div className="loading-text">Loading, please wait...</div>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="payment-validation-container">
            <h2>Successful Payment Validations</h2>
            <table className="payment-validation-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Transaction ID</th>
                        <th>Event Category</th>
                        <th>Team Name</th>
                        <th>Registration/Payment Time</th>
                        <th>Payment Done</th>
                        <th>Validation Success</th>
                        <th>Validation Reason</th>
                        <th>Validation Done</th>
                    </tr>
                </thead>
                <tbody>
                    {payments.map((payment) => (
                        <tr key={payment.id}>
                            <td>{payment.id}</td>
                            <td>{payment.transactionId}</td>
                            <td>{payment.eventTeam.event.category.category}</td>
                            <td>{payment.eventTeam.team.name}</td>
                            <td>{new Date(payment.paymentTimestamp).toLocaleString()}</td>
                            <td>{payment.paymentDone ? 'Yes' : 'No'}</td>
                            <td>{payment.validationSuccess ? 'Yes' : 'No'}</td>
                            <td>{payment.validationReason || 'N/A'}</td>
                            <td>{payment.paymentValidation ? 'Yes' : 'No'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index)}
                        className={`page-button ${index === currentPage ? 'active' : ''}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default SuccessPayment;
